/* hide scrollbar but allow scrolling */
.tenant-products-scroll {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-x: scroll;
}

.tenant-products-scroll::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
