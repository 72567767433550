.tagify {
    border-color: rgb(121, 76, 203);
    width: 100%;
}

.tagify__input {
    margin: 10px;
}

.tags-input {
    width: 100%;
}

.tagify__tag {
    margin-top: 10px;
}